@tailwind base;
@tailwind components;
@tailwind utilities;

/* Headline */
@font-face {
  font-family: "KoenigBauerH";
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerHeadline/KoenigBauerH-Light.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerH";
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerHeadline/KoenigBauerH-Book.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerH";
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerHeadline/KoenigBauerH-Medium.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerH";
  font-weight: 600;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerHeadline/KoenigBauerH-SemiBold.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerH";
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerHeadline/KoenigBauerH-Bold.woff) format("woff");
}

/* Text */
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 100;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-Hairline.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 200;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-ExtraLight.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-Light.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-Book.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-Medium.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 600;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-SemiBold.woff) format("woff");
}
@font-face {
  font-family: "KoenigBauerT";
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/KoenigBauerText/KoenigBauerT-Bold.woff) format("woff");
}

@import "slick.css";

div {
  /* @apply border border-red-500; */
}

body,
html,
#root {
  @apply w-full h-full overflow-hidden;
}

body {
  @apply fixed;
  @apply pb-safe-bottom;
}

#root {
  @apply relative;
}

#NFTDetails a,
#PrintDetails a,
#AboutDetails a {
  @apply underline;
}

.grid-cols-\[auto\2c 1fr\] {
  grid-template-columns: auto 1fr;
}
.grid-cols-\[1fr\2c auto\] {
  grid-template-columns: auto 1fr;
}

details summary::-webkit-details-marker,
details summary::marker {
  padding-right: 0px;
  margin-right: 0px;
}

/* reset focus states */
*,
*:focus,
*:hover {
  outline: 0px transparent !important;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.slick-slide:not(.slick-active) .tutorialCard {
  @apply bg-white/5 text-white/5;
}
